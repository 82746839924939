import Vue from 'vue'
import '@/plugins/vuetify'
import '@/plugins/vue-bus'
import App from './App.vue'
import router from './router'
import store from './store'
import './registry'
// import './registerServiceWorker'

import VueYouTubeEmbed from 'vue-youtube-embed'

Vue.use(VueYouTubeEmbed)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
