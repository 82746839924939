











































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Segment from '@/engine/models/segment'
import GameState from '@/engine/models/game-state'
import Segments from '@/engine/common/segments'
import States from '@/engine/common/states'

@Component
export default class DartBoard extends Vue {
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean

  private BRAUN_DUNKEL: string = 'rgb(202, 181, 149)'
  private BRAUN_HELL: string = 'rgba(202, 181, 149, 0.4)'

  @Prop()
  private state!: GameState

  private readonly lightShotColor: string = 'rgba(74, 42, 67, .3)'
  private readonly darkShotColor: string = 'rgba(74, 42, 67, .5)'
  private readonly bullseyeShotColor: string = 'rgba(234, 102, 81, 1)' // Hellrot
  private readonly bullShotColor: string = 'rgba(246, 216, 77, 1)' // Hellgelb
  private readonly bullseyeActiveColor: string = 'rgba(112, 235, 250, 1)' // Hellblau
  private readonly bullShotActiveColor: string = 'rgba(0, 180, 255, 1)' // Dunkelblau
  private readonly bullseyePFR: string = 'rgba(246, 216, 77, .8)' // Hellgelb
  private readonly bullseyePFRBoard: string = 'rgb(0, 148, 255)' // Hellgelb
  private readonly bullPFR: string = 'rgba(216, 184, 25, .8)' // Dunkelgelb
  private readonly bullPFRBoard: string = 'rgb(0, 148, 255)' // Dunkelgelb

  private readonly buildingColor: string = '#F6D82C'
  private readonly boardWindowBuildingColor: string = '#FFFF00'
  private readonly borderColor: string = '#FFFFFF4D'

  private readonly DARK = 'dark'
  private readonly LIGHT = 'light'

  private readonly colors: { [id: string]: 'dark' | 'light' } = {
    e00: this.LIGHT,
    b00: this.DARK,
    l01: this.DARK,
    t01: this.LIGHT,
    u01: this.DARK,
    d01: this.LIGHT,
    l02: this.LIGHT,
    t02: this.DARK,
    u02: this.LIGHT,
    d02: this.DARK,
    l03: this.LIGHT,
    t03: this.DARK,
    u03: this.LIGHT,
    d03: this.DARK,
    l04: this.DARK,
    t04: this.LIGHT,
    u04: this.DARK,
    d04: this.LIGHT,
    l05: this.DARK,
    t05: this.LIGHT,
    u05: this.DARK,
    d05: this.LIGHT,
    l06: this.DARK,
    t06: this.LIGHT,
    u06: this.DARK,
    d06: this.LIGHT,
    l07: this.LIGHT,
    t07: this.DARK,
    u07: this.LIGHT,
    d07: this.DARK,
    l08: this.LIGHT,
    t08: this.DARK,
    u08: this.LIGHT,
    d08: this.DARK,
    l09: this.DARK,
    t09: this.LIGHT,
    u09: this.DARK,
    d09: this.LIGHT,
    l10: this.LIGHT,
    t10: this.DARK,
    u10: this.LIGHT,
    d10: this.DARK,
    l11: this.DARK,
    t11: this.LIGHT,
    u11: this.DARK,
    d11: this.LIGHT,
    l12: this.LIGHT,
    t12: this.DARK,
    u12: this.LIGHT,
    d12: this.DARK,
    l13: this.LIGHT,
    t13: this.DARK,
    u13: this.LIGHT,
    d13: this.DARK,
    l14: this.LIGHT,
    t14: this.DARK,
    u14: this.LIGHT,
    d14: this.DARK,
    l15: this.DARK,
    t15: this.LIGHT,
    u15: this.DARK,
    d15: this.LIGHT,
    l16: this.DARK,
    t16: this.LIGHT,
    u16: this.DARK,
    d16: this.LIGHT,
    l17: this.DARK,
    t17: this.LIGHT,
    u17: this.DARK,
    d17: this.LIGHT,
    l18: this.LIGHT,
    t18: this.DARK,
    u18: this.LIGHT,
    d18: this.DARK,
    l19: this.DARK,
    t19: this.LIGHT,
    u19: this.DARK,
    d19: this.LIGHT,
    l20: this.LIGHT,
    t20: this.DARK,
    u20: this.LIGHT,
    d20: this.DARK,
  }

  private channel: BroadcastChannel | null = null

  private boardUpdates: any = {}

  get segments(): Segment[] {
    return this.state.segments
  }

  public resetSegmentsHit() {
    this.boardUpdates = {}
    this.forAllSegments(this.setInitialFill)

    if (this.state.gameplay.enabledTopToBottom) {
      this.forAllSegments(this.disableHighlight)
      if (States.countriesLeftToConquer(this.state)) {
        const topRegion = Segments.findTopFreeRegion(this.state)
        if (topRegion) {
          this.forRegion(parseInt(topRegion, 10), this.enableHighlight)
        }
      }
    }

    if (this.state.gameplay.enabledBullForLand) {
      this.disableHighlight('b00')
      const notAllConquered = States.countriesLeftToConquer(this.state)
      const bullNotHit = this.state.hasNotHit('b00')
      const bullseyeNotHit = this.state.hasNotHit('e00')
      if (notAllConquered && bullNotHit && bullseyeNotHit) {
        this.enableHighlight('b00')
        // Wenn PFR aktiviert und noch kein Pol getroffen, zeige Pols in Gelb.
        // Bei Easy Nordpol wird Bullseye weiterhin in Hellblau angezeigt.
        if (!this.state.bullsEyeEnabled) {
          this.setFill('e00', this.bullseyePFR)
          this.boardUpdates.e00 = this.bullseyePFRBoard
        }
        // Bei Easy Südpol wird Bull weiterhin in Dunkelblau angezeigt.
        if (!this.state.bullEnabled) {
          this.setFill('b00', this.bullPFR)
          this.boardUpdates.b00 = this.bullPFRBoard
        }
      }
    }
    
    this.setOwnedColors()
    this.setShotColors()

    Object.keys(this.boardUpdates).forEach((key) => {
      this.setBoardWindowFill(key, this.boardUpdates[key])
    })
  }

  @Watch('state', { deep: true })
  private stateChanged(val: GameState, oldVal: GameState) {
    this.resetSegmentsHit()
  }

  private setInitialFill(id: string) {
    switch (id) {
      case 'e00':
        this.setFill(
          id,
          this.state.bullsEyeEnabled
            ? this.bullseyeActiveColor
            : this.BRAUN_HELL,
        )
        this.boardUpdates[id] = this.state.bullsEyeEnabled
            ? this.boardWindowBuildingColor
            : this.BRAUN_HELL
        break

      case 'b00':
        this.setFill(
          id,
          this.state.bullEnabled
            ? this.bullShotActiveColor
            : this.BRAUN_DUNKEL,
        )
        this.boardUpdates[id] = this.state.bullEnabled
            ? this.boardWindowBuildingColor
            : this.BRAUN_DUNKEL
        break

      default:
        switch (this.colors[id]) {
          case this.LIGHT:
            this.setFill(id, this.BRAUN_HELL)
            this.boardUpdates[id] = this.BRAUN_HELL
            break
            
          case this.DARK:
            this.setFill(id, this.BRAUN_DUNKEL)
            this.boardUpdates[id] = this.BRAUN_DUNKEL
            break
        }
        break
    }
  }

  private enableHighlight(id: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
      switch (this.colors[id]) {
        case this.LIGHT:
          elem.classList.add('highlighted-light')
          break
        case this.DARK:
          elem.classList.add('highlighted-dark')
          break
      }
    }
    if (this.state.gameplay.enabledTopToBottom && this.state.gameplay.enabledConquerAll) {
      const seg = this.state.segments.find((s) => s.id === id)
      if (!seg || !seg.owner) {
        this.enableBoardWindowHighlight(id)        
      }
    } else {
      this.enableBoardWindowHighlight(id)
    }
  }

  private enableBoardWindowHighlight(id: string) {
    switch (this.colors[id]) {
      case this.LIGHT:
        this.broadcastColor(id, '+highlighted-light')
        break
      case this.DARK:
        this.broadcastColor(id, '+highlighted-dark')
        break
    }
  }

  private disableHighlight(id: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
      switch (this.colors[id]) {
        case this.LIGHT:
          elem.classList.remove('highlighted-light')
          break
        case this.DARK:
          elem.classList.remove('highlighted-dark')
          break
      }
    }
    this.disableBoardWindowHighlight(id)
  }

  private disableBoardWindowHighlight(id: string) {
    switch (this.colors[id]) {
      case this.LIGHT:
        this.broadcastColor(id, '-highlighted-light')
        break
      case this.DARK:
        this.broadcastColor(id, '-highlighted-dark')
        break
    }
  }

  private setShotColors() {
    for (const shot of this.state.history) {
      const elem: HTMLElement | null = this.$el.querySelector(`#${shot}`)
      const isOwned = elem && elem.style.fill !== this.BRAUN_HELL && elem.style.fill !== this.BRAUN_DUNKEL

      this.disableHighlight(shot)

      switch (shot) {
        case 'e00':
          this.setFill(shot, this.bullseyeShotColor)
          if (!isOwned) {
            this.boardUpdates[shot] = this.state.team.color
          }
          break

        case 'b00':
          this.setFill(shot, this.bullShotColor)
          if (!isOwned) {
            this.boardUpdates[shot] = this.state.team.color
          }
          break

        default:
          switch (this.colors[shot]) {
            case this.LIGHT:
              this.setFill(shot, this.lightShotColor)
              if (!isOwned) {
                this.boardUpdates[shot] = this.state.team.color
              }
              break
            case this.DARK:
              this.setFill(shot, this.darkShotColor)
              if (!isOwned) {
                this.boardUpdates[shot] = this.state.team.color
              }
              break
          }
          break
      }
    }
  }

  private setOwnedColors() {
    for (const seg of this.segments) {
      if (this.state.gameplay.enabledConquerAll) {
        if (seg.owner) {
          this.setFill(seg.id, seg.owner.color)
          this.setBorder(seg.id, this.borderColor)
          this.boardUpdates[seg.id] = seg.owner.color
        }
      } else if (seg.alph === 'd' && seg.houses > 0) {
        this.setFill(seg.id, this.buildingColor + '66')
        this.setBorder(seg.id, this.borderColor)
        this.boardUpdates[seg.id] = this.boardWindowBuildingColor
      } else if (seg.alph === 't' && seg.villas > 0) {
        this.setFill(seg.id, this.buildingColor + '66')
        this.setBorder(seg.id, this.borderColor)
        this.boardUpdates[seg.id] = this.boardWindowBuildingColor
      } else if (seg.owner) {
        this.setSegmentAlphaFill(seg, seg.owner.color, '99', 'ff')
        this.setBorder(seg.id, this.borderColor)
        this.boardUpdates[seg.id] = seg.owner.color
        if (seg.alph === 'd' || seg.alph === 't') {
          this.boardUpdates[seg.id] = this.shadeColor(seg.owner.color, 20)
        } else {
          this.boardUpdates[seg.id] = seg.owner.color
        }
      }
    }
  }

  private setBorder(id: string, color: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
      elem.style.strokeWidth = '1'
      elem.style.stroke = color
    }
  }

  private shadeColor(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16)
    let G = parseInt(color.substring(3, 5), 16)
    let B = parseInt(color.substring(5, 7), 16)

    R = Math.floor(R * (100 + percent) / 100)
    G = Math.floor(G * (100 + percent) / 100)
    B = Math.floor(B * (100 + percent) / 100)

    R = (R < 255) ? R : 255
    G = (G < 255) ? G : 255
    B = (B < 255) ? B : 255

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16))
    const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16))
    const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16))

    return '#' + RR + GG + BB
  }

  private setSegmentAlphaFill(
    seg: Segment,
    color: string,
    lightAlpha: string,
    darkAlpha: string,
  ) {
    if (seg.alph === 'd' || seg.alph === 't') {
      this.setFill(seg.id, color + lightAlpha)
    } else {
      this.setFill(seg.id, color + darkAlpha)
    }
  }

  private setFill(id: string, color: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
      elem.style.fill = color
    }
  }

  private setBoardWindowFill(id: string, color: string) {
    if (color === this.BRAUN_DUNKEL || color === this.BRAUN_HELL) {
      this.broadcastColor(id, '')
    } else {
      this.broadcastColor(id, color)
    }
  }

  private broadcastColor(id: string, color: string) {
    if (this.channel) {
      this.channel.postMessage({ id, color })
    }
  }

  private addClickEvent(id: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
      elem.addEventListener('click', (e) => {
        if (!this.disabled) {
          e.stopPropagation()
          const target = e.currentTarget as SVGSVGElement
          this.$emit('click', target, id)
        }
      })
    }
  }

  private forAllSegments(action: (id: string) => any) {
    action('b00')
    action('e00')
    for (let i = 1; i <= 20; i++) {
      this.forRegion(i, action)
    }
  }

  private forRegion(i: number, action: (id: string) => any) {
    const id = `${i}`.padStart(2, '0')
    action(`l${id}`)
    action(`t${id}`)
    action(`u${id}`)
    action(`d${id}`)
  }

  private mounted() {
    this.channel = new BroadcastChannel('board')
    setTimeout(() => {
      this.forAllSegments(this.disableBoardWindowHighlight)
      this.forAllSegments(this.addClickEvent)
      this.resetSegmentsHit()
    }, 1000)
  }
}
