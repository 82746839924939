

























































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'

@Component
export default class IconBar extends Vue {
  @Prop()
  private state!: GameState

  @Prop({ default: 65 })
  private size!: number
}
