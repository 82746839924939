






























































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'
import Player from '@/engine/models/player'
import Team from '@/engine/models/team'

@Component
export default class PrimaryScoreboard extends Vue {
  @Prop()
  private state!: GameState

  private dialog: boolean = false
  private active: number = 0
  private showMap: boolean = true

  get teamSlot1(): Team | null {
    if (this.state.teams.length > 0) {
      return this.state.teams[0]
    }
    return null
  }

  get teamSlot2(): Team | null {
    if (this.state.isDouble) {
      return this.state.teams[0]
    }
    if (this.state.teams.length > 2) {
      return this.state.teams[1]
    }
    return null
  }

  get teamSlot3(): Team | null {
    if (this.state.isDouble) {
      return this.state.teams[1]
    }
    if (this.state.teams.length > 2) {
      return this.state.teams[2]
    }
    return null
  }

  get teamSlot4(): Team | null {
    if (this.state.isDouble) {
      return this.state.teams[1]
    }
    if (this.state.teams.length === 2) {
      return this.state.teams[1]
    }
    if (this.state.teams.length > 3) {
      return this.state.teams[3]
    }
    return null
  }

  get playerSlot1(): Player | null {
    const team = this.teamSlot1
    if (team != null) {
      return team.players[0]
    }
    return null
  }

  get playerSlot2(): Player | null {
    const team = this.teamSlot2
    if (team != null) {
      if (this.state.isDouble) {
        return team.players[1]
      }
      return team.players[0]
    }
    return null
  }

  get playerSlot3(): Player | null {
    const team = this.teamSlot3
    if (team != null) {
      return team.players[0]
    }
    return null
  }

  get playerSlot4(): Player | null {
    const team = this.teamSlot4
    if (team != null) {
      if (this.state.isDouble) {
        return team.players[1]
      }
      return team.players[0]
    }
    return null
  }

  get activePlayerSlot(): number {
    if (this.state.player === this.playerSlot1) {
      return 0
    } else if (this.state.player === this.playerSlot2) {
      return 1
    } else if (this.state.player === this.playerSlot3) {
      return 2
    } else if (this.state.player === this.playerSlot4) {
      return 3
    } else {
      return -1
    }
  }

  get numShots(): number {
    return this.state.history.length
  }

  get numDarts(): number {
    return this.state.gameplay.numDarts
  }

  get completed(): boolean {
    return this.numShots === this.numDarts
  }

  public open(n?: number) {
    if (n === undefined) {
      this.active = 0
    } else {
      this.active = Math.min(n, this.numShots)
    }
    this.dialog = true
  }

  @Watch('state', { deep: true })
  private stateChanged(val: GameState) {
    this.active = this.numShots
    if (val.isGameOver || val.showSchnapszahlDialog || val.selfDefeat) {
      this.close()
    }
  }

  private close() {
    this.dialog = false
  }

  private next() {
    if (this.state.gameplay.numDarts > this.state.history.length) {
      this.select(this.active, '---')
    } else {
      this.$emit('next')
    }
  }

  private select(n: number, id: string) {
    if (this.completed || n < this.numShots) {
      this.$emit('replace', n, id)
    } else {
      this.$emit('shot', id)
    }

    if (this.active < this.numDarts - 1) {
      this.active++
    } else {
      this.active = 0
    }
  }
}
