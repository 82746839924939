






























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Team from '@/engine/models/team'
import GameState from '@/engine/models/game-state'

@Component
export default class VictoryDialog extends Vue {
  @Prop()
  private state!: GameState

  get winner(): string {
    if (this.state.gameplay.enabled11R) {
      const winners11 = this.state.teams.filter((t) => t.numRegions >= 11)
      if (winners11.length > 0) {
        return this.getTeamName(winners11[0])
      }
    }
    const winners = this.state.teams.filter((t) => t.score > 0)
    if (winners.length > 0) {
      return this.getTeamName(winners[0])
    }
    return ''
  }

  get round(): number {
    return this.state.round
  }

  private getTeamName(team: Team) {
    if (team.players.length === 1) {
      return team.players[0].name
    }
    return `Team ${team.id + 1}`
  }

  private correct() {
    this.$emit('correct')
  }

  private startNewGame() {
    this.$emit('newgame')
  }

  private startRevanche() {
    this.$emit('revanche')
  }
}
